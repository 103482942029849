<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="back-icon-svg"
  >
    <polyline points="15 18 9 12 15 6"></polyline>
  </svg>
</template>

<script>
export default {
  name: 'IconBack'
}
</script>
