<template>
  <button class="icon-close content-only" @click="$emit('close')">
    <IconX :alt="phrases.shared.close" />
  </button>
</template>

<script>
import IconX from '@/components/Icons/IconX.vue'

export default {
  name: 'CloseButton',
  components: {
    IconX
  }
}
</script>
