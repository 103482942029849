<template>
  <div class="alert-pill-wrapper" :class="level" data-test-id="alert-pill">
    <BadgeInfo
      v-if="level === 'info'"
      palette="blue"
      :border="{ width: 0 }"
      :size="{ width: 16, height: 16 }"
      class="badge-info"
    />
    <span class="alert-copy" :class="level">
      {{ copy }}
    </span>
  </div>
</template>

<script>
import BadgeInfo from '@/components/Badge/BadgeInfo.vue'

export default {
  name: 'AlertPill',
  components: {
    BadgeInfo
  },
  props: {
    copy: String,
    level: String
  },
  mounted() {
    this.$analytics.track({
      event: 'Viewed Alert',
      payload: {
        alertPillType: this.level,
        alertPillCopy: this.copy
      }
    })
  }
}
</script>
<style lang="scss" scoped>
.alert-pill-wrapper {
  width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  text-align: left;
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 16px;
  color: var(--gray-800);

  .alert-copy {
    text-wrap: pretty; /* not yet supported on all browsers */
  }

  &::before {
    margin-top: 2px;
    margin-right: 8px;
    height: 16px;
    width: 16px;
  }

  &.info {
    background-color: var(--info-50);
    color: var(--info-500);
    .dark & {
      background-color: var(--info-800);
      color: var(--info-300);
    }
  }

  .badge-info {
    margin-right: 8px;
    // Optically align with text
    margin-top: 1px;
  }

  &.warn {
    background-color: var(--warn-100);
    color: var(--warn-500);
    align-items: center;
    &::before {
      content: url('/img/icon-alert-triangle.svg');
    }
    .dark & {
      background-color: var(--warn-700);
      color: var(--warn-100);
    }
  }

  &.error {
    background-color: var(--error-100);
    color: var(--error-800);
    &::before {
      content: url('/img/icon-octagon-x.svg');
    }
    .dark & {
      background-color: var(--error-700);
      color: var(--error-100);
    }
  }
}
</style>
