<template>
  <!-- Override static aria-describedby since this modal doesn't need a description -->
  <ModalContent aria-describedby="">
    <ModalBody>
      <ModalContentWrap>
        <ModalLabel :level="3" class="mb-2">{{
          phrases.shared.paymentMethod
        }}</ModalLabel>
        <FormSelect
          :data="{
            options: paymentMethodsAsFormSelectOptions,
            label: phrases.shared.selectPaymentMethod
          }"
          :show-label="false"
          :init-value="paymentMethod._id"
          :focus-delay="400"
          ignore-init-update-event
          :task-branded="false"
          @inputUpdate="handleSelection"
          @inputEnter="handleSelection"
        >
          <template v-slot:option-content="option">
            <PaymentMethodOptionContent :payment-method="option" />
          </template>
        </FormSelect>
        <div class="action-list">
          <Card
            v-if="canAddCards"
            is="button"
            class="btn btn-add-card"
            @click="handleClickAddCard"
          >
            <span aria-hidden="true">+ </span>{{ phrases.addCard.addCard }}
          </Card>
          <TextButton
            @click="handleClickClose"
            :text="phrases.shared.close"
            class="btn-close"
          />
        </div>
      </ModalContentWrap>
    </ModalBody>
  </ModalContent>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import { getPaymentMethodType, paymentMethodIsSupported } from '@/util/pay-link'
import { emitSdkEvent } from '@/util/sdk'
import { ROUTES, SDK_EVENT_TYPES } from '@/util/constants'
import Card from '@/components/Shared/Card.vue'
import ModalLabel from '@/components/Modal/ModalLabel.vue'
import FormSelect from '@/components/FormTypes/FormSelect.vue'
import ModalContent from '@/components/Modal/ModalContent.vue'
import ModalBody from '@/components/Modal/ModalBody.vue'
import ModalContentWrap from '@/components/Modal/ModalContentWrap.vue'
import PaymentMethodOptionContent from '@/components/Switch/PaymentMethodOptionContent.vue'
import TextButton from '@/components/Form/TextButton.vue'

export default {
  name: 'PayLinkSelectPaymentMethodModal',
  components: {
    Card,
    ModalLabel,
    FormSelect,
    ModalContentWrap,
    ModalBody,
    ModalContent,
    PaymentMethodOptionContent,
    TextButton
  },
  computed: {
    ...mapState('user', ['userData']),
    ...mapState('company', ['activeConnector']),
    ...mapState('payLink', ['paymentMethod']),
    ...mapGetters('payLink', ['supportedPaymentMethodTypes']),
    ...mapGetters('main', ['isIOSPlatform']),
    ...mapGetters('user', ['cardPaymentMethods']),
    canAddCards() {
      return Boolean(
        this.userData?.customer?.features?.payLink?.userProvidedCards?.enabled
      )
    },
    paymentMethodsAsFormSelectOptions() {
      return [
        ...[...this.cardPaymentMethods].reverse(),
        ...(this.userData.accounts || [])
      ].map((paymentMethod) => ({
        ...paymentMethod,
        value: paymentMethod._id,
        disabled: !paymentMethodIsSupported(
          paymentMethod,
          this.supportedPaymentMethodTypes
        ),
        unsupported: this.phrases.shared.unsupported,
        ariaLabel: this.phrases.shared.selectPaymentMethod,
        ariaDescription: this.phrases.shared.paymentMethodAriaDescription({
          paymentMethod
        })
      }))
    }
  },
  methods: {
    ...mapMutations('payLink', ['setPaymentMethod']),
    ...mapActions('modal', ['closeModal']),
    handleSelection(paymentMethod) {
      this.$analytics.track({
        event: 'Changed Payment Method',
        payload: {
          paymentMethodType: getPaymentMethodType(paymentMethod)
        }
      })
      this.setPaymentMethod(paymentMethod)
      this.closeModal()
    },
    async handleClickAddCard() {
      await this.closeModal()

      if (this.isIOSPlatform) {
        emitSdkEvent(SDK_EVENT_TYPES.AUTOMATION_HANDOFF, {
          type: 'add-card'
        })
      } else {
        this.$router.push({ name: ROUTES.ADD_CARD })
      }
    },
    async handleClickClose() {
      await this.closeModal()
    }
  }
}
</script>

<style lang="scss" scoped>
.dynamic-header {
  margin-bottom: 12px;
}

.btn-add-card {
  width: 100%;
  text-align: center;
}

.btn-close {
  // Soon TextButton will have 44px height by default. Hard-coding for now
  // until that change goes out.
  min-height: 44px;
}
</style>
