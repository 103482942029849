export const adpInterstitial = {
  iDoNotRememberMyCredentials: 'No, no me acuerdo',
  iRememberMyCredentials: 'Si, conozco mis credenciales',
  title: '¿Recuerda su ID de usuario y contraseña de ADP?',
  subtitle:
    'Está bien si no lo recuerdas. Puede iniciar sesión verificando cierta información.',
  wrongConnector: 'Yo no uso ADP',
  coAuthTitle: 'Encontremos su cuenta de ADP',
  coAuthSubtitle:
    'Atomic utilizará sus datos personales para buscar su cuenta de ADP para que pueda actualizar su depósito directo.'
}
