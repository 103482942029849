<template>
  <div class="action-bar">
    <PNCActionBar v-if="isPNCFeature" />
    <MainActionBar v-else />
  </div>
</template>

<script>
import MainActionBar from './MainActionBar.vue'
import PNCActionBar from './PNCActionBar.vue'
import { featureUniqueForPNC } from '@/util/customization'
import { mapState } from 'vuex'

export default {
  name: 'ActionBar',
  components: {
    MainActionBar,
    PNCActionBar
  },
  computed: {
    ...mapState('user', ['userData']),
    isPNCFeature() {
      const customer = this.userData?.customer
      return featureUniqueForPNC({ customer })
    }
  }
}
</script>

<style scoped>
.action-bar {
  width: 100%;
}
</style>
