export const legal = {
  acceptTermsAndConditions: 'Declaro que acepto los ',
  termsAndConditions: 'términos y condiciones',
  legalDisclaimerPretext: 'Al proceder, usted acepta los términos de Atomic',
  actionPrivacyPolicy: 'Política de Privacidad',
  actionTerms: 'Términos',
  actionTermsOfUse: 'Términos de Uso',
  termsConditionsSee: 'Consultar',
  termsConditionsAnd: 'y',
  termsTitle: 'Términos de Uso',
  termsLastModified: 'Última modificación: 11 de junio de 2024',
  privacyTitle: 'Política de Privacidad',
  privacyLastModified: 'Última modificación: 11 de junio de 2024',
  privacyExhibit: 'Anexo A'
}
