import { Quantum } from '@atomicfi/quantum-js'
import Analytics from '@/plugins/analytics'
import { SDK_EVENT_TYPES } from '@/util/constants'
import { emitSdkEvent } from '@/util/sdk'
import {
  navigateToCompanyLogin,
  navigateToPayLinkPreLoginDeeplink
} from '@/util/navigation'
import { requestInterceptionScript } from '@/util/client-side-automation/page-scripts'
import { receivedInterceptedRequest } from '@/util/client-side-automation/received-intercepted-request'

export async function executeUserAction({ store, router }) {
  const { id } = store.state.taskWorkflow.tasks[0].action
  const action = store.getters['taskWorkflow/userAction']

  await _executeAction({ id, action, store, router })
}

export function completeUserAction() {
  Analytics.get().track({
    event: 'Completed User Action'
  })
}

function _launchedAction(id) {
  Analytics.get().track({
    event: 'Launched User Action',
    payload: {
      id
    }
  })
  emitSdkEvent(SDK_EVENT_TYPES.LAUNCH, { id })
}

async function _executeAction({ id, action, store, router }) {
  if (action.url) {
    await _openUrl({ id, url: action.url })
  } else if (action.flow) {
    await _executeFlow({
      id,
      companyId: action.companyId,
      store,
      router
    })
  }
}

async function _openUrl({ id, url }) {
  _launchedAction(id)

  const { page } = await Quantum.launch()
  await page.show()
  await page.goto(url)
  await page.on('closed', () => {
    completeUserAction()
    emitSdkEvent(SDK_EVENT_TYPES.FINISH)
  })
}

async function _executeFlow({ id, companyId, store, router }) {
  _launchedAction(id)

  const userAction = store.getters['taskWorkflow/userAction']

  // We need to show the view first, so the native controllers update to present
  // on top of transact, instead of the customers controller
  if (userAction.flow !== 'refresh') {
    emitSdkEvent(SDK_EVENT_TYPES.AUTOMATION_HANDOFF, {
      type: 'show-view'
    })
  }

  // So just wait a tick before launching the page
  setTimeout(async () => {
    // If the user action account is linked, we need to add the page for the task to use
    if (store.getters['taskWorkflow/userActionAccountIsLinked']) {
      const { page } = await Quantum.launch({ interceptRequests: false })
      await store.dispatch('userDeviceAutomation/addPage', page)

      await navigateToCompanyLogin({
        companyId,
        store,
        router
      })

      await _setupPageForUserActionFlow({ store, page })
    }
    // Otherwise, the page will be created when the user links their account through the authenticate flow
    else {
      console.log('navigating to paylink pre login deeplink', 'haha', companyId)
      await navigateToPayLinkPreLoginDeeplink({
        companyId,
        store,
        router
      })
    }
  }, 100)
}

async function _setupPageForUserActionFlow({ store, page }) {
  const config =
    store.getters['userDeviceAutomation/parsedUserAutomationDeviceConfig']

  await page.on('finished', async () => {
    await page.evaluate(requestInterceptionScript({ config }), [
      config.requestHook,
      config.responseHook
    ])
  })

  await page.on('dispatch', (event) => {
    switch (event.detail.data?.type) {
      case 'request':
        return receivedInterceptedRequest({
          store,
          request: event.detail.data.request
        })
    }
  })
}
