import { PRODUCTS } from '@/util/constants'

export const detail = {
  linkedDate: (date) => `Vinculada ${date}`,
  managedByCompany: {
    title: ({ companyName, managingCompanyName }) =>
      `Tu suscripción de ${companyName} es administrada por ${managingCompanyName}`,
    message: ({ managingCompanyName, product }) =>
      product === PRODUCTS.PRESENT
        ? `Inicia sesión en tu cuenta de ${managingCompanyName} para continuar.`
        : `Inicia sesión en tu cuenta de ${managingCompanyName} para finalizar la actualización de tu método de pago.`
  },
  userInputRequired: {
    title: 'Finalizar la verificación de su cuenta',
    message: ({ companyName, product }) =>
      product === PRODUCTS.PRESENT
        ? `${companyName} necesita verificar su cuenta para cambiar su pago.`
        : `${companyName} necesita verificar su cuenta para actualizar su método de pago.`
  }
}
