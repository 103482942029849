<template>
  <div class="badge-wrap">
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getGlobalCSSValue } from '@/util/general'

export default {
  name: 'BadgeBase',
  props: {
    backgroundColor: { String, required: true },
    size: Object,
    border: Object
  },
  computed: {
    ...mapGetters('theme', ['useDarkMode']),
    badgeBackgroundColor() {
      return this.backgroundColor
    },
    badgeWidth() {
      return `${this.size?.width || 24}px`
    },
    badgeHeight() {
      return `${this.size?.height || 24}px`
    },
    borderWidth() {
      return `${this.border?.width ?? 2}px`
    },
    borderColor() {
      return this.border?.color
        ? this.border.color
        : this.useDarkMode
        ? getGlobalCSSValue('--gray-1000')
        : getGlobalCSSValue('--white')
    }
  }
}
</script>
<style lang="scss" scoped>
.badge-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--containerBorderRadius);
  border: solid v-bind(borderWidth) v-bind(borderColor);
  background: v-bind(badgeBackgroundColor);
  text-align: center;
  width: v-bind(badgeWidth);
  height: v-bind(badgeHeight);
  min-width: v-bind(badgeWidth);
}
</style>
