<template>
  <div class="terms-conditions-wrap">
    <h2>{{ phrases.legal.termsTitle }}</h2>
    <p>{{ phrases.legal.termsLastModified }}</p>
    <p>
      These Terms of Use (&quot;Terms&quot;) are a contract between you and
      Atomic FI, Inc. (&quot;Atomic&quot;) and govern your use of each of the
      Atomic products and services for which these Terms are presented to you
      (the &quot;Service&quot;) and, if applicable, your Atomic end-user account
      for the Service.
      <strong
        >You can obtain a copy of these Terms on the Atomic website or by
        contacting us at support@atomicfi.com.</strong
      >
    </p>
    <p>
      If you do not agree to these Terms, do not use the Service.
      <strong
        >By using the Service or by registering for or using an Atomic account,
        you accept and agree to be bound by these Terms, including the mandatory
        arbitration provision, class action waiver, and limitations of liability
        included below.</strong
      >
    </p>
    <h4>1. &nbsp;About These Terms</h4>
    <p>
      Please read these Terms carefully before using the Service and check them
      again each time you use the Service in the future, as they may change, as
      indicated by the &quot;last modified&quot; date above. Accessing or using
      the Service indicates that you accept and agree to be bound by the
      then-current version of these Terms, as well as future modifications to
      these Terms that may be necessary to effectuate their essential purpose
      and provide the Service effectively, in full. You acknowledge (a) that you
      have read and understood these Terms; and (b) that these Terms shall have
      the same force and effect as a signed agreement.
    </p>
    <h4>2. &nbsp;About Atomic&#39;s Service</h4>
    <p>
      The Service provides a way for you to connect a third-party mobile or web
      service or application (&ldquo;Third-Party Application&rdquo;), typically
      related to your employment or a merchant you do business with,&nbsp;with a
      mobile or web application or service (&ldquo;Partner Application&rdquo;)
      provided by our business partner or our business partner&rsquo;s customer
      (each, a &ldquo;Partner&rdquo;). The Service, which you typically access
      through the Partner Application, or a URL delivered to you by text or
      email, is designed to enable you to automate tasks you otherwise would be
      able to manually complete in the Third-Party Application, such as a switch
      of your payroll direct deposit or bill payment information. It facilitates
      operations and reports that you select, initiate, or authorize through or
      in connection with the Service (&quot;Authorized Operations&quot;). The
      Authorized Operations usually involve accessing your Third-Party
      Application to obtain information needed by Atomic and the Partner and to
      make any required changes.
    </p>
    <p>
      Atomic may also offer its own front-end interface as part of the Service
      to let you access or manage certain information or features, which may
      involve creating an Atomic account. Atomic reserves the right to modify
      all or any part of the Service or to suspend or stop providing all or
      portions of the Service at any time.
    </p>
    <h4>
      3. &nbsp;Grant of Authority to Atomic to Act as Agent, and Power of
      Attorney
    </h4>
    <p>
      In order to protect and exercise your rights to your information and data,
      as well as your general rights as a consumer, as recognized under various
      federal and state laws, including and without limitation, the Utah
      Consumer Privacy Act, Section 1033 of the Dodd-Frank Act, and Sections
      1798.100 and 1798.185 of the California Consumer Privacy Act, as
      implemented by regulations and as amended, it may be necessary for Atomic
      to act as your authorized agent in order to access your Third-Party
      Application. By using the Service, you authorize Atomic to access your
      Third-Party Application on your behalf, as well as your account(s) for
      your Third-Party Application, as necessary to obtain your information in a
      readily usable format and interact with the features, functionalities, and
      structures of your Third-Party Application to facilitate the efficient and
      orderly transfer of your information by reasonable means, including but
      not limited to using account login credentials and other authentication
      information you furnish, in order to perform the Authorized Operations.
      You acknowledge that this may involve passing information from your
      Third-Party Application to the Partner, and may also involve accessing,
      modifying, adding to, or deleting information in your Third-Party
      Application.
    </p>
    <p>
      By using the Service, you authorize Atomic to act as your agent, and also
      grant a limited power of attorney to Atomic to do the foregoing on your
      behalf in connection with the Service. This authorization to Atomic to act
      as your agent, as well as this limited power of attorney, includes the
      right for Atomic to act as your true and lawful agent and
      attorney-in-fact, with full power to act for you and in your name, place,
      and stead, in any and all capacities, to use your account authentication
      information, to access websites, servers, and accounts, including those of
      third parties as necessary to exercise and effectuate your rights on your
      behalf, and to access, retrieve, store, modify, use, and share documents
      and data, all as necessary or useful to perform Authorized Operations or
      as otherwise contemplated in these Terms, including without limitation to
      make direct deposit, deduction or payment method updates, obtain and
      provide income, employment, or individual or company identity information,
      and obtain periodic &nbsp;information about direct deposit or deduction
      updates, with the full power and authority as your agent to do anything in
      connection with such activities that you could lawfully do directly.
    </p>
    <p>
      You agree that the Partner and the provider(s) of your Third-Party
      Application may rely on the foregoing grant of agency and limited power of
      attorney and are authorized to provide Atomic with the access to the
      Third-Party Application necessary to facilitate an efficient and orderly
      transfer of your information to the Partner, and information Atomic may
      need or request in connection with the Service, and are intended
      third-party beneficiaries of this provision.
    </p>
    <p>
      This grant of agency and limited power of attorney will remain in effect
      as long as reasonably necessary for Atomic to provide the Service, unless
      earlier revoked by you in writing by notice to Atomic (in which case you
      acknowledge that Atomic will not be responsible for completing the
      Service, and you will indemnify Atomic for any liability for its resulting
      failure to do so).
    </p>
    <h4>4. &nbsp;Privacy</h4>
    <p>
      Our Privacy Policy, attached as Exhibit A, describes the information
      Atomic collects when you use the Service. It also describes how Atomic
      uses and shares any personal information you share with it. The Privacy
      Policy is a part of these Terms. By agreeing to these Terms, you are also
      consenting to Atomic&#39;s collection, use, and sharing of their personal
      information in accordance with our Privacy Policy.
    </p>
    <h4>5. &nbsp;Conditions of Use</h4>
    <p>
      Accurate Information. Atomic needs accurate information about you,
      including your authentication information for the Third-Party Application,
      to accurately perform the Service. You agree to provide true, accurate,
      current, and complete information about yourself and your account, and you
      agree not to misrepresent your identity or the account, nor to provide any
      information regarding any other person&#39;s account unless you are
      authorized to do so as their agent (see &quot;Agents&quot; below). You
      represent and warrant that you are entitled to submit any data that you
      furnish to Atomic or through the Service for the uses described herein,
      and that such data is accurate, complete, and up to date.
    </p>
    <p>
      <strong>Prohibited Conduct</strong>. You may not (and you agree not to):
    </p>
    <ul>
      <li>
        use the Service for anything other than to select, initiate, authorize,
        receive, or manage Authorized Operations;
      </li>
      <li>
        copy, modify, duplicate, create derivative works from, frame, mirror,
        republish, download, display, transmit, or distribute all or any portion
        of the Service or Atomic&#39;s related documentation (as applicable) in
        any form or media or by any means;
      </li>
      <li>
        reverse-engineer the Service or access or decompile Atomic&#39;s
        underlying software, or attempt to do so or assist, direct, or authorize
        any third party to do so, except to the extent (if any) that applicable
        law would prohibit this restriction;
      </li>
      <li>
        access all or any part of the Service or its associated documentation in
        order to provide services to third parties or to build a product or
        service which competes with any part of the Service;
      </li>
      <li>
        use the Service for any fraudulent, criminal, tortious, or other
        unlawful or inappropriate purpose;
      </li>
      <li>
        license, sell, rent, lease, transfer, assign, distribute, display,
        disclose, or otherwise commercially exploit the Service, or make the
        Service available to any third party;
      </li>
      <li>
        use any method designed to scrape, mine, or extract data from or through
        the Service, nor develop any application or interface that interacts
        with the Service, without Atomic&#39;s prior written consent;
      </li>
      <li>
        introduce to or through the Service any viruses or other files, code, or
        programs designed to interrupt, destroy, interfere with, intercept, or
        limit the functionality of the Service or any data or systems; or
      </li>
      <li>
        attempt to circumvent any security or authentication measures for the
        Service, or to obtain, or assist third parties in obtaining, access to
        the Service other than as provided by Atomic (or, where applicable, the
        Partner) under these Terms.
      </li>
    </ul>
    <p>
      <strong>Age</strong>. You must be at least 18 years of age, and above the
      age of majority as defined by the laws that apply where you live, to use
      the Service.
    </p>
    <p>
      <strong>No Advice</strong>. You acknowledge that Atomic is not a fiduciary
      (except as a limited agent under Section 3), financial institution,
      financial or investment adviser, or provider of investment, tax, or legal
      advice, and does not endorse any company, property, product, service,
      security, or instrument.
    </p>
    <p>
      <strong>Payment of Fees</strong>. You acknowledge that the Authorized
      Operations may be subject to fees charged by the Partner or the provider
      of your Third-Party Application in connection with your account with them.
      You are responsible for informing yourself of any such fees and paying
      them. Atomic does not charge you for your use of the Service.
    </p>
    <p>
      <strong>Agents</strong>. If an agent uses the Service on behalf of another
      person, (a) all references to &quot;you&quot; throughout these Terms will
      include that person; (b) the agent represents that they are authorized to
      accept these Terms on that person&#39;s behalf; and (c) in the event that
      the agent or the other person violates these Terms, the agent and other
      person both agree to be responsible to Atomic for such violation.
    </p>
    <p>
      <strong>Atomic Accounts</strong>. If Atomic offers accounts for the
      Service and you register or allow a Partner to register for you: (a) you
      must provide accurate registration information and promptly update this
      information if it changes, and (b)&nbsp;you agree and understand that you
      are responsible for maintaining the confidentiality and security of your
      Atomic authentication information and for all activity (including the
      activity of others) occurring under your account or using your
      authentication information.
    </p>
    <p>
      <strong>Security Notification</strong>. You agree to promptly notify
      Atomic if you discover, suspect, or are informed of unauthorized account
      access or unauthorized use of your authentication information. In the
      instance of a breach of your accounts as contemplated under this Section,
      Atomic should promptly be notified at security@atomicfi.com.
    </p>
    <p>
      <strong>Violations and Enforceability</strong>. Atomic may immediately
      suspend or terminate your access to the Service in the event it determines
      or believes that you have violated or will violate any of the foregoing
      conditions or prohibitions, or if it otherwise believes in its sole
      discretion that your access to or use of the Service may expose Atomic or
      others to any harm or liability. The enforcement of these Terms is solely
      at Atomic&#39;s discretion and the failure to do so does not constitute a
      waiver of Atomic&#39;s right to enforce in other instances or at a future
      time.
    </p>
    <h4>6. &nbsp;Partners and Third-Party Applications</h4>
    <p>
      Your relationship with the Partner and provider(s) of the Third-Party
      Application, and their provision, processing, and use of any data,
      reports, or other output in relation to the Service, will be subject to
      your applicable agreement(s) with those entities, including their privacy
      policies. ATOMIC DOES NOT CONTROL THE PARTNER&#39;S USE OF YOUR DATA OR OF
      ANY REPORTS OR OTHER OUTPUT OF THE SERVICE. ATOMIC&#39;S INTEGRATION WITH
      PARTNERS AND THIRD-PARTY APPLICATIONS IS PROVIDED AS A CONVENIENCE TO YOU,
      AND WE MAKE NO REPRESENTATIONS OR ENDORSEMENTS REGARDING, AND TAKE NO
      RESPONSIBILITY FOR, THOSE ENTITIES OR THEIR PRODUCTS, SERVICES, ACTS, OR
      OMISSIONS, INCLUDING ANY PARTNER APPLICATION OR THIRD-PARTY APPLICATION OR
      ANY CONTENTS THEREOF.
    </p>
    <h4>7. &nbsp;Intellectual Property Ownership</h4>
    <p>
      <strong>Service</strong>. Atomic and its licensors, as applicable, reserve
      and retain all right, title, and interest in and to the Service, all
      associated software and technologies, and all intellectual property rights
      therein, including any reports and data generated by Atomic in connection
      with the Service; provided, however, that Atomic does not claim ownership
      of the login credentials or other information that you submit when
      registering for or using the Service, or any data about you that Atomic
      receives from the Partner or your Third-Party Application.
    </p>
    <p>
      <strong>Feedback</strong>. To the extent you provide Atomic with any
      suggestions, inquiries, or other feedback relating to the Service
      (&quot;Feedback&quot;), Atomic will own (and you hereby assign to Atomic)
      all right, title, and interest in and to such Feedback. You acknowledge
      and represent that any Feedback is given voluntarily and is not subject to
      any obligation or expectation of confidentiality. You understand that
      Atomic does not pay any compensation for Feedback and is under no
      obligation to respond to or use any Feedback.
    </p>
    <p>
      <strong>Branding</strong>. Atomic&rsquo;s and its Partners&rsquo;
      trademarks and logos, product and service names, slogans, and other
      branding, including the look and feel of the Service, are trademarks or
      other property of Atomic or its applicable Partners, as the case may be.
      You may not copy, modify, or use them without express permission from the
      owner.
    </p>
    <h4>8. &nbsp;Indemnity</h4>
    <p>
      You agree to defend, indemnify, and hold Atomic, its affiliates, and its
      and their respective officers, directors, employees, agents, shareholders,
      licensors, licensees, customers (including Partner), and suppliers
      (together, the &ldquo;Atomic Parties&rdquo;), harmless from and against
      all third-party claims, actions, proceedings, regulatory investigations,
      damages, losses, judgments, settlements, costs, and expenses (including
      attorney fees), arising from or in connection with your use of the
      Service, your conduct in connection with the Service, your violation of
      these Terms, or your infringement or other violation of any third-party
      rights or applicable laws or regulations. You agree that Atomic may, at
      its option (and at your expense), control the defense or settlement of any
      such claims by third parties, in which case you agree to cooperate with
      the Atomic Parties in defending any such third-party claims.
    </p>
    <p>
      This indemnification shall apply to the fullest extent permitted by
      applicable law and is in addition to, and not in lieu of, any other
      indemnities set forth in a written agreement between you and the Atomic
      Parties
    </p>
    <h4>9. &nbsp;Disclaimers</h4>
    <p>
      THE SERVICE IS PROVIDED &ldquo;AS IS,&rdquo; AND MAY BE SUBJECT TO BUGS,
      ERRORS, AND OTHER PROBLEMS. TO THE FULLEST EXTENT PERMITTED BY LAW, THE
      ATOMIC PARTIES DO NOT MAKE ANY WARRANTY OF ANY KIND WITH RESPECT TO THE
      SERVICE, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT
      NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
      PURPOSE, OR NON-INFRINGEMENT, OR ANY WARRANTY THAT THE SERVICE IS FREE
      FROM DEFECTS, VIRUSES, SECURITY VULNERABILITIES, OR INTERRUPTIONS.
    </p>
    <p>
      YOUR USE OF THE SERVICE AND ALL INFORMATION, CONTENT, PRODUCTS, AND
      SERVICES INCLUDED IN, LINKED TO, OR ACCESSIBLE THROUGH THE SERVICE IS AT
      YOUR SOLE RISK. YOU ASSUME THE ENTIRE RISK AS TO THE QUALITY,
      AVAILABILITY, PERFORMANCE. YOU SHOULD VERIFY ALL RESULTS OF THE SERVICE.
    </p>
    <p>
      THE SERVICE IS CONTROLLED AND OFFERED BY ATOMIC FROM ITS FACILITIES IN THE
      UNITED STATES OF AMERICA. YOU ARE RESPONSIBLE FOR DETERMINING WHETHER,
      HOW, AND WHERE YOU MAY USE THE SERVICE UNDER LOCAL AND ALL OTHER
      APPLICABLE LAWS, RESTRICTIONS, AND REGULATIONS
    </p>
    <h4>10. &nbsp;Limitation of Liability</h4>
    <p>
      TO THE FULLEST EXTENT PERMITTED BY LAW, THE ATOMIC PARTIES WILL NOT BE
      LIABLE UNDER THESE TERMS FOR ANY: (A) INDIRECT, SPECIAL, INCIDENTAL,
      CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES; (B) LOSS OF OR ERRORS IN
      DATA, OR LOSS OF OR INTERRUPTIONS TO ACCESS OR USE OF THE SERVICE; OR (C)
      COST OF COVER OR LOSS OF BUSINESS, REVENUES, OR PROFITS (IN EACH CASE
      WHETHER DIRECT OR INDIRECT), EVEN IF THE ATOMIC PARTIES KNEW OR SHOULD
      HAVE KNOWN THAT SUCH DAMAGES WERE POSSIBLE. TO THE FULLEST EXTENT
      PERMITTED BY LAW, THE ATOMIC PARTIES&rsquo; AGGREGATE LIABILITY IN
      CONNECTION WITH THESE TERMS WILL NOT EXCEED ONE HUNDRED DOLLARS
      (US$100.00). THE PARTIES AGREE THAT THE WAIVERS AND LIMITATIONS SPECIFIED
      IN THIS SECTION APPLY REGARDLESS OF THE FORM OF ACTION, WHETHER IN
      CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE, AND
      WILL SURVIVE AND APPLY EVEN IF ANY LIMITED REMEDY SPECIFIED IN THESE TERMS
      IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
    </p>
    <h4>11. Consumer Arbitration; Class Action Waiver; Disputes</h4>
    <p>
      <strong>Mandatory Arbitration</strong>. All claims and disputes, including
      all statutory claims and disputes, arising out of or relating to these
      Terms or your use of the Service will be finally resolved by binding
      arbitration on an individual basis, unless you opt out of arbitration as
      explicitly described below, except that you and Atomic are not required to
      arbitrate any dispute in which either party seeks equitable relief for
      alleged infringement or misappropriation of copyrights, trademarks, trade
      names, logos, trade secrets, or patents. This means that, except for the
      equitable relief described in the previous sentence, neither Atomic nor
      you will sue in court before a judge or jury, unless you opt out of
      arbitration as provided below.
    </p>
    <p>
      <strong>Arbitration Rules</strong>. The arbitration will be administered
      by the American Arbitration Association (&ldquo;AAA&rdquo;), in accordance
      with its Consumer Arbitration Rules (the &ldquo;Rules&rdquo;), except to
      the extent they conflict with these Terms. If the AAA is not available to
      arbitrate, the parties will select an alternative arbitral forum. The
      Rules are available online at www.adr.org or by calling AAA at
      1-800-778-7879.
    </p>
    <p>
      <strong>Arbitration Procedures</strong>. For any arbitration under these
      Terms or in relation to the Service, one neutral arbitrator (appointed in
      accordance with the Rules) will decide the dispute, and the
      arbitrator&rsquo;s decision will be final. The arbitrator may award
      declaratory or injunctive relief only for the individual claims between
      Atomic and you. Any claims or disputes involving less than US$10,000 may
      be resolved through binding non-appearance-based arbitration, at the
      option of the party seeking relief. For claims or disputes where the
      amount sought is US$10,000 or more, the right to an in-person oral hearing
      will be determined by the Rules. The arbitrator will be bound to follow
      applicable federal and state laws and regulations in deciding all issues
      and in rendering any award. Atomic and you will be entitled to invoke the
      rules of discovery applicable to state court proceedings. The arbitration
      proceedings are not appealable, and any party to any award rendered in any
      arbitration proceeding will be entitled to have judgment entered on that
      award.
    </p>
    <p>
      <strong>Arbitration Venue</strong>. Any hearings will be held in a
      location within 100 miles of your residence unless you reside outside of
      the United States or unless the parties agree otherwise. If you reside
      outside of the U.S., the arbitrator shall give the parties reasonable
      notice of the date, time and place of any hearings. If non-appearance
      arbitration is elected, the arbitration will be conducted by phone,
      online, written submissions, or a combination of the three, at the
      election of the party initiating arbitration, and the arbitration will not
      involve a personal appearance by parties or witnesses unless the parties
      mutually agree otherwise. The language of the arbitration will be English.
    </p>
    <p>
      <strong>Costs</strong>. Atomic will cover the costs of the initial
      arbitration fees, except that if you demand arbitration first, you may be
      required to pay the claimant&rsquo;s initial arbitration filing fees or
      case management fees required by the Rules up to $125 USD, and if the
      arbitrator finds your complaint to be frivolous, you agree to reimburse
      Atomic&rsquo;s costs. The arbitrator may not award or assess punitive
      damages against either party.
    </p>
    <p>
      <strong>Class-Action Waiver</strong>. ATOMIC AND YOU AGREE THAT ANY
      PROCEEDINGS TO RESOLVE ANY DISPUTE, INCLUDING ARBITRATION AND LITIGATION
      IN COURT, WILL BE CONDUCTED INDIVIDUALLY ONLY. Neither Atomic nor you will
      seek (or be permitted) to have any dispute heard as a class action, a
      class wide arbitration, a private attorney-general action, or any other
      proceeding in which either of Atomic or you act(s) or propose(s) to act as
      a representative for others. Atomic and you also agree that no arbitration
      or other proceeding will be joined or combined with another arbitration or
      proceeding without the written consent of Atomic, you, and every other
      party to the affected arbitration(s) or proceeding(s). TO THE FULLEST
      EXTENT PERMITTED UNDER APPLICABLE LAW, YOU WAIVE ANY RIGHT TO BRING OR
      PARTICIPATE IN ANY ACTION AGAINST ATOMIC AS A REPRESENTATIVE OR MEMBER OF
      A CLASS.
    </p>
    <p>
      <strong>Opting Out</strong>. You may opt out of mandatory arbitration
      under the provisions set forth above; to do so, you must notify Atomic in
      writing of your decision to opt out in accordance with this Section no
      later than thirty (30) days after first using the Service under these
      Terms. If you opt out as described in this paragraph, neither you nor
      Atomic can force the other to arbitrate under these Terms, and disputes
      will be resolved as described in the following paragraph. Your notice must
      include your name and address, your username and the email address you
      used to set up your account for the Service (if applicable), and an
      unequivocal statement that you want to opt out of arbitration under these
      Terms. Send such notice to 2890 East Cottonwood Parkway, Suite 400,
      Cottonwood Heights, UT 84121 USA. In the event of a dispute between you
      and Atomic, to invoke your opt-out right, you must produce a copy of your
      opt-out notice, as well as proof of mailing of your opt-out notice within
      the prescribed period. Opting out does not affect any previous, other, or
      future arbitration agreement between you and Atomic.
    </p>
    <p>
      <strong>Courts</strong>. If you opt out of arbitration, each of you and
      Atomic irrevocably (a) consents to the exclusive jurisdiction and venue of
      the state and federal courts in the State of Utah in connection with any
      matter arising out of these Terms, (b) waives any objection to such
      jurisdiction or venue, (c) agrees not to commence any legal proceedings
      related hereto except in such courts, (d) consents to and agrees to accept
      service of process to vest personal jurisdiction over it in any such
      courts and (e) waives any right to trial by jury in any action in
      connection with these Terms.
    </p>
    <p>
      <strong>Amendments to Disputes Clause</strong>. These arbitration and
      disputes provisions may be amended from time to time in accordance with
      Section 1 of these Terms. You may reject any change Atomic makes to these
      arbitration and disputes provisions (other than changes to Atomic&rsquo;s
      addresses for notices) by sending Atomic written notice within thirty (30)
      days after first becoming subject to the amended Terms. Send such notice
      rejecting such changes to 2890 East Cottonwood Parkway, Suite 400,
      Cottonwood Heights, UT 84121 USA. Otherwise, your continued use of the
      Service beyond such thirty (30)-day period indicates your agreement to the
      amended arbitration and disputes provisions. For the avoidance of doubt,
      no unilateral amendment will retroactively modify agreed arbitration and
      disputes provisions of this Section 11 for then-pending disputes unless
      you and Atomic both expressly agree otherwise.
    </p>
    <p>
      <strong>Temporary Injunctive Relief Through the Court</strong>. In aid of
      arbitration, Atomic or you may seek temporary and/or preliminary
      injunctive relief, without the obligation of posting a bond, in both the
      state and federal courts of Salt Lake County, Utah at any time before an
      arbitration demand has been filed and served, or before an arbitrator has
      been selected.
    </p>
    <p>
      <strong>Confidentiality of Arbitration</strong>. The arbitrator, Atomic
      and you shall maintain the confidentiality of any arbitration proceedings,
      communications, judgments, and awards. The duty of confidentiality does
      not apply to the extent that disclosure is necessary to prepare for, or
      conduct, the arbitration hearing on the merits, in connection with a court
      application for a preliminary remedy, or in connection with a judicial
      challenge to an arbitration award or its enforcement, or to the extent
      that disclosure is otherwise required by law or judicial decision.
    </p>
    <p>
      <strong>Timing</strong>. Any claim arising out of, or related to, these
      Terms or the Service must be filed within one (1) year after such claim
      arose; otherwise, the claim is permanently barred and may not be asserted
      by either party. If this section is deemed to be unenforceable for any
      reason, then the claim shall be brought in the shortest time period
      allowed for under the law.
    </p>
    <h4>12. &nbsp;Governing Law</h4>
    <p>
      Except to the extent inconsistent with or preempted by federal law, the
      laws of the State of Utah, without regard to principles of conflict of
      laws, will govern these Terms and any claim or dispute that has arisen or
      may arise between you and Atomic.
    </p>
    <h4>13. &nbsp;Entire Agreement</h4>
    <p>
      These Terms, as supplemented by the Privacy Policy, are the complete and
      exclusive agreement between you and Atomic relating to the Service or the
      other matters described herein. These Terms supersede any previous
      proposal or prior agreement, oral or written, and any other communications
      between you and Atomic relating to the subject matter of the Terms. The
      Terms, as amended from time to time, shall prevail over any subsequent
      oral communications between you and Atomic. Notwithstanding the foregoing,
      Atomic may, now or in the future, supply different or additional terms in
      relation to certain Service features. If there is a conflict between these
      Terms and the additional terms, the additional terms will control (but
      solely as they relate to the applicable Service features).
    </p>
    <h4>14. &nbsp;Miscellaneous</h4>
    <p>
      <strong>Electronic Notices and Disclosures</strong>. You acknowledge and
      agree that Atomic may provide notices and other disclosures electronically
      (with the same effect as a paper copy) by posting such notices or other
      disclosures electronically through the Service or on Atomic&rsquo;s
      website or by emailing you at any email address provided to Atomic by you.
      Such notices or other disclosures shall be considered received by you
      after being posted on the Service or Atomic&rsquo;s website or twenty-four
      (24) hours after being emailed to you.
    </p>
    <p>
      <strong>Text Messaging &amp; Phone Calls</strong>. You agree that Atomic
      and those acting on our behalf may call and send you text &nbsp;messages
      at the phone number you provide us. These calls and messages may include
      operational calls or messages about your use of the Service. Standard data
      and message rates may apply whenever you send or receive such calls or
      messages, as specified by your carrier. IF YOU WISH TO OPT OUT OF SUCH
      CALLS, EMAIL SUPPORT@ATOMICFI.COM. IF YOU WISH TO OPT &nbsp;OUT OF TEXTS,
      TEXT THE WORD &ldquo;STOP&rdquo; TO THE NUMBER FROM WHICH YOU RECEIVE THE
      MESSAGES. You may continue to receive calls and text messages for a short
      period while we process your request, including a message confirming the
      receipt of your opt-out request.
    </p>
    <p>
      <strong>Call Recording</strong>. You agree that Atomic and its agents,
      representatives, affiliates, and anyone calling or receiving your call on
      Atomic&rsquo;s behalf may record the call. You agree that such recordings
      may be kept for training purposes, and as a record of your interaction
      with Atomic, and may be used as evidence in any legal or arbitration
      proceedings related to you that may occur.
    </p>
    <p>
      <strong>Severability</strong>. If any provision or provisions of these
      Terms shall be held to be invalid, illegal, or unenforceable, the
      validity, legality, and enforceability of the remaining provisions shall
      not be affected thereby and Atomic and you shall negotiate replacement
      provisions, for those provisions which are held invalid, illegal, or
      unenforceable, which as closely as possible express the intent of those
      original provisions.
    </p>
    <p>
      <strong>Waiver</strong>. The failure of Atomic to exercise or enforce any
      right or provision of these Terms will not operate as a waiver of such
      right or provision.
    </p>
    <p>
      <strong>Headings</strong>. The section titles in these Terms are for
      convenience only and have no legal or contractual effect.
    </p>
    <p>
      <strong>Survival</strong>. Any rights and obligations which by their
      nature extend beyond the terms of these Terms shall survive and continue
      after any expiration or termination and shall bind you and their legal
      representatives, successors, heirs, and assigns, where assignment is
      expressly permitted.
    </p>
    <div class="contact-info">
      <p><strong>Questions on these Terms of Use please contact:</strong></p>
      <p>
        Atomic FI Inc.<br />
        2890 Cottonwood Parkway #400<br />
        Cottonwood Heights, UT 84121<br />
        support@atomicfi.com
      </p>
    </div>
    <br />
    <br />
    <br />
  </div>
</template>

<script>
export default {
  name: 'TermsConditions'
}
</script>
<style lang="scss" scoped>
.terms-conditions-wrap {
  padding-right: 1.6rem;

  h3,
  h4 {
    margin-top: 32px;
    line-height: 125%;
    font-size: 1.8rem;
  }

  p {
    line-height: 125%;
    margin-top: 16px;
    font-size: 14px;
    text-align: justify;

    .dark & {
      color: var(--gray-400);
    }
  }

  a {
    display: inline;
    word-break: break-all;
    font-size: 1.4rem;
    line-height: 1;
    color: var(--info-500);

    .dark & {
      color: var(--info-300);
    }
  }

  ul {
    margin-top: 24px;
    padding: 0 24px 0 48px;
    list-style-type: circle;
    text-align: justify;

    li {
      line-height: 125%;
      margin-top: 16px;
      font-size: 1.4rem;
      color: var(--gray-700);
      text-align: justify;

      .dark & {
        color: var(--gray-400);
      }
    }
  }
}
</style>
