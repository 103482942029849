<template>
  <div
    class="action-bar"
    :class="{
      'align-right': !showBackButton && inSdk && showCloseButton,
      hidden: coverActionBar
    }"
  >
    <transition name="fade">
      <button
        v-if="showBackButton"
        class="icon-back content-only"
        @click="handleBack"
        data-test-id="back"
        id="backButton"
        :aria-label="backButtonText"
      >
        <IconBack aria-hidden="true" />
        <span v-if="showBackButtonText" class="back-button-text">
          {{ backButtonText }}
        </span>
      </button>
    </transition>
    <transition name="fade">
      <CloseButton
        v-if="showExitButton && inSdk"
        v-store-focused-element:@click="handleClose"
        data-test-id="close-x"
        id="closeButton"
        :disabled="state === 'loading'"
      />
    </transition>
  </div>
</template>

<script>
import {
  APP_EVENTS,
  ROUTES,
  TASK_WORKFLOW_VIEW,
  SDK_EVENT_TYPES,
  HANDOFF_EVENTS
} from '@/util/constants'
import { mapState, mapGetters, mapActions } from 'vuex'
import {
  appSubscribe,
  appUnsubscribe,
  initiateExit,
  determineSdkEventTypeOnClose,
  shouldUseHandoff
} from '@/util/app-event'
import { backButtonHandler } from '@/util/back-button'
import CloseButton from '@/components/Shared/CloseButton.vue'
import IconBack from '@/components/Icons/IconBack.vue'
import {
  backButtonTextBasedOnRoute,
  headingDataBasedOnRoute
} from '@/util/general'
import { useAuthenticationService } from '@/machines/authentication'

export default {
  name: 'MainActionBar',
  components: { CloseButton, IconBack },
  data() {
    return {
      state: 'idle'
    }
  },
  computed: {
    ...mapState('company', ['_id']),
    ...mapState('main', ['coverActionBar', 'inSdk', 'deeplink']),
    ...mapState('taskWorkflow', ['taskWorkflowState']),
    ...mapState('experiment', ['ldFlags']),
    ...mapGetters('main', ['showBackButton']),
    ...mapGetters('theme', ['showBackButtonText', 'showCloseButton']),
    ...mapGetters('i18n', ['phrases']),
    backButtonText() {
      return backButtonTextBasedOnRoute({
        routeName: this.currentRoute(),
        store: this.$store,
        authService: useAuthenticationService()
      })
    },
    headingData() {
      return headingDataBasedOnRoute({
        routeName: this.currentRoute(),
        store: this.$store,
        authService: useAuthenticationService()
      })
    },
    reRenderFocusHeadingToRestoreFocus() {
      return this.headingData?.text
    },
    showExitButton() {
      const currentRoute = this.$route.name
      return (
        currentRoute &&
        currentRoute !== ROUTES.INITIALIZE &&
        this.taskWorkflowState?.view !== TASK_WORKFLOW_VIEW.IN_PROGRESS &&
        this.showCloseButton
      )
    },
    showHeading() {
      return !(this.showBackButtonText && this.showBackButton)
    }
  },
  methods: {
    ...mapActions('search', ['trackSearchPerformanceEvents']),
    currentRoute() {
      return this.$router?.currentRoute.value.name
    },
    handleBack() {
      backButtonHandler({ store: this.$store })
    },
    handleClose() {
      this.state = 'loading'
      this.trackSearchPerformanceEvents()

      this.$analytics.track({
        event: 'Clicked Close',
        payload: { exitScreen: this.$route.path }
      })

      const closeAppIfSearchTrackingNotComplete = () => {
        this.state = 'idle'
        initiateExit({
          store: this.$store,
          eventType: this.determineSdkEvent(),
          route: this.currentRoute(),
          ...(shouldUseHandoff({
            store: this.$store,
            handoffEvent: HANDOFF_EVENTS.EXIT_PROMPT
          }) && { handoffEvent: HANDOFF_EVENTS.EXIT_PROMPT })
        })
      }

      setTimeout(closeAppIfSearchTrackingNotComplete, 500)
    },
    determineSdkEvent() {
      return determineSdkEventTypeOnClose(this.taskWorkflowState)
        ? SDK_EVENT_TYPES.FINISH
        : SDK_EVENT_TYPES.CLOSE
    }
  },
  created() {
    appSubscribe(APP_EVENTS.HANDLE_CLOSE, this.handleClose)
  },
  unmounted() {
    appUnsubscribe(APP_EVENTS.HANDLE_CLOSE, this.handleClose)
  }
}
</script>

<style lang="scss" scoped>
.action-bar {
  // Layout & Positioning
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  min-height: calc(var(--headerHeight) + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) 24px 0;

  // Flexbox
  display: flex;
  align-items: center;
  justify-content: flex-start;

  // Transitions & Animation
  transition: opacity 0.5s 0.3s;
  opacity: 1;

  // Modifiers
  &.hidden {
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
  }

  &.align-right {
    justify-content: flex-end;
  }

  // Child Components
  .title {
    animation: fadeIn 250ms ease-in 250ms forwards;
    opacity: 0;
    transition: padding-left 250ms cubic-bezier(0.39, 0.575, 0.565, 1);

    &.has-back-button {
      padding-left: 24px;
      font-size: 1.6rem;
    }
  }

  // Icon Styles - Shared Properties
  %icon-base {
    cursor: pointer;
    display: flex;
    margin-left: auto;
    margin-right: -8px;
    padding: 8px;
    color: var(--gray-600);
    stroke: var(--gray-600);
    transition: color 200ms ease-in-out;

    &.dark {
      color: var(--gray-500);
      stroke: var(--gray-500);
    }
  }

  .icon-close {
    @extend %icon-base;
  }

  .icon-back {
    @extend %icon-base;
    left: 8px;
    position: absolute;
    z-index: 1;

    &.fade-leave-active {
      display: none;
    }

    .back-button-text {
      color: var(--gray-700);
      font-family: var(--font);
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  // Layout Utilities
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  #page-title {
    width: 100%;
  }
}

// Animations
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
