export const legal = {
  acceptTermsAndConditions: 'I agree to the ',
  termsAndConditions: 'terms and conditions',
  legalDisclaimerPretext: `By continuing, you accept Atomic's`,
  actionPrivacyPolicy: 'Privacy policy',
  actionTerms: 'Terms',
  actionTermsOfUse: 'Terms of Use',
  termsConditionsSee: 'See',
  termsConditionsAnd: 'and',
  termsTitle: 'Terms of Use',
  termsLastModified: 'Last modified June 11, 2024',
  privacyTitle: 'Privacy Policy',
  privacyLastModified: 'Last modified: June 11, 2024',
  privacyExhibit: 'Exhibit A'
}
