import { TASK_WORKFLOW_TYPES } from './constants'

export function featureUniqueForPaypal({ customer }) {
  const paypalIds = [
    '605cbbf8b56383000938db51', // Main
    '61a8d9367621a700085402e2', // QA
    '61fac310f88c780009211aa4' // L&P
  ]
  return paypalIds.includes(customer?._id) //Paypal
}

export function featureUniqueForDave({ customer }) {
  return customer?._id === '60f6ff6e89644a000902f375' //Dave
}

export function featureUniqueForUSBank({ customer }) {
  return customer?._id === '617c61559a470100092f1619' // US Bank
}

export function featureUniqueForHAndRBlock({ customer }) {
  return customer?._id === '63ab59f9802ac8000798de47'
}

export function featureUniqueForGo2Bank({ customer }) {
  const go2BankIds = [
    '620fc4d0fc1a1000097e4ba0', // GreenDot
    '620fc59efc1a1000097e5402' // PLS
  ]
  return go2BankIds.includes(customer?._id)
}

export function featureUniqueForAlbert({ customer }) {
  return customer?._id === '603e8e1131f7f40008082b6c'
}

export function featureUniqueForNovaCredit({ customer }) {
  return customer?._id === '6543b74b033d8300085ef3ef'
}

export function featureUniqueForOne({ customer }) {
  return customer?._id === '60807e481be10300096c6502'
}

export function featureUniqueForMolecularVIP({ customer }) {
  return customer?._id === '66621f1f3a8d0b927cc3a1c8'
}

export function featureUniqueForStashworks({ customer }) {
  return customer?._id === '660b25a47130b2abfd607af4'
}

export function featureUniqueForMastercard({ customer }) {
  return customer?._id === '66ba5eb744eba5a9130b18bf'
}

export function featureUniqueForCapitalOne({ customer }) {
  return customer?._id === '651abd113bddd100083127bc'
}

export function featureUniqueForSpruce({ customer }) {
  return customer?._id === '62bf47b4101c3e00099a3153'
}

export function featureUniqueForChime({ customer }) {
  return customer?._id === '5f3aea53bb5a5e00081be2a7'
}

export function featureUniqueForAlkamiChildren({ customer }) {
  const alkamiChildrenIds = [
    '662ff02afb36e2b862e49b66',
    '656a1b575c0794ca29102e8b' // PremierOne CU
  ]
  return alkamiChildrenIds.includes(customer?._id)
}

export function featureUniqueForPNC({ customer }) {
  return customer?._id === '64de845e5b49ea00082b44d4'
}

export function isQuickbooksWorkforceCompany({ company }) {
  return [
    '5fc810d63279fe0009c493e9',
    '644853ea57bf2c00084a558a',
    '5e4c4d18b7d75c37aac54d76'
  ].includes(company._id)
}

export function isMyPay({ company }) {
  return ['5fc810d63279fe0009c493ea'].includes(company._id)
}

export function isWalmart({ company }) {
  const id = company?._id || company
  return ['5ec2fbf4398fed000810a42c'].includes(id)
}

export function isSamsClub({ company }) {
  return ['60a2febfa875ea0009055dd9'].includes(company._id)
}

export function isSpark({ company }) {
  return ['64dd200c654c8500080ae5fd', '64a755875d1c4c2a6f40ccbb'].includes(
    company._id
  )
}

export function isNotAPerfectPayLinkCompanyYet({ company }) {
  const id = company._id || company
  return [
    '652801e35d8a530008e9e2cd' // spotify
  ].includes(id)
}

export function isTurboTax(companyId) {
  return ['6572112000bd56d1b83d3836'].includes(companyId)
}

export function isPaycom({ company }) {
  return ['5f5007f218d35500081c0c83'].includes(company._id)
}

export function isDFAS({ company }) {
  return ['5fc810d63279fe0009c493ea'].includes(company._id)
}

export function excludeCompanyFromDefaultSearchSuggestions({
  operation,
  customer,
  suggestion
}) {
  if (
    featureUniqueForHAndRBlock({ customer }) &&
    operation === TASK_WORKFLOW_TYPES.TAX
  ) {
    return !isMyPay({ company: suggestion }) && !isDFAS({ company: suggestion })
  } else if (featureUniqueForNovaCredit({ customer })) {
    return !isPaycom({ company: suggestion })
  }

  return true
}
